<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Un pajarito me contó</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Blog - Entrada</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start Blog Detail Area -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-12">

                        <div class="blog-single">
                            <div class="card blog--card">
                                <figure>
                                    <a href=""><img :src="`assets/images/blog/${blog_post.imagen}`" class="img-fluid" alt=""></a>
                                    <figcaption>
                                        <a href=""><i class="mdi mdi-image-outline"></i></a>
                                    </figcaption>
                                </figure>
                                <div class="card-body">
                                    <h5><a href="">{{blog_post.titulo}}</a></h5>
                                    <ul class="post-meta d-flex">
                                        <li>{{blog_post.fecha}}</li>
                                        <li>por <a href="">{{blog_post.autor}}</a></li>
                                        <li>en <a href="">{{blog_post.seccion}}</a></li>
                                        <!-- <li><a href="">6 Comments</a></li> -->
                                    </ul>
                                    <!-- <p>Using v-html directive: -->
                                    <p class="m-0"><span v-html="blog_post.texto_largo"></span></p><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="sidebar">
                            <!-- search widget -->
                            <!-- <div class="widget-wrapper">
                                <div class="search-widget">
                                    <form action="#">
                                        <div class="input-group">
                                            <input type="text" placeholder="Buscar">
                                            <button type="submit"><i class="mdi mdi-24px mdi-magnify"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div> -->
                            <!-- category widget -->
                            <!-- <div class="widget-wrapper">
                                <div class="widget-default">
                                    <div class="widget-header">
                                        <h6 class="widget-title">Categorias</h6>
                                    </div>
                                    <div class="widget-content">
                                        <div class="category-widget">
                                            <ul>
                                                <li><a href=""><i class="mdi mdi-chevron-right mdi-18px mr-1"></i>Historias</a></li>
                                                <li><a href=""><i class="mdi mdi-chevron-right mdi-18px mr-1"></i>Eventos</a></li>
                                                <li><a href=""><i class="mdi mdi-chevron-right mdi-18px mr-1"></i>Interés general</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- popular post -->
                            <div class="widget-wrapper">
                                <div class="widget-default">
                                    <div class="widget-header">
                                        <h6 class="widget-title">Publicaciones recientes</h6>
                                    </div>
                                    <div class="widget-content">
                                        <div class="sidebar-post">
                                            <!-- Miniaturas -->
                                            <div class="post-single" v-for="(blog_single_post) in last_blog_posts" v-if="blog_single_post.estado == 1">
                                                <div class="d-flex align-items-center">
                                                    <a :href="`/blogdetail?post_id=${blog_single_post.id}`"><img :src="`assets/images/blog/thumbs/t_${blog_single_post.imagen}`" alt="" width="70" height="70"></a>
                                                    <p><span>{{blog_single_post.fecha}}</span> <span>por <a href="">{{blog_single_post.autor}}</a></span></p>
                                                </div>
                                                <a :href="`/blogdetail?post_id=${blog_single_post.id}`" class="post-title">{{blog_single_post.titulo}}</a>
                                            </div>
                                            <!-- Fin miniaturas -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- tags -->
                            <!-- <div class="widget-wrapper">
                                <div class="widget-default">
                                    <div class="widget-header">
                                        <h6 class="widget-title">Etiquetas poulares</h6>
                                    </div>
                                    <div class="widget-content">
                                        <div class="tags-widget">
                                            <ul>
                                                <li><a href="">Postres</a></li>
                                                <li><a href="">Merengue</a></li>
                                                <li><a href="">Naturaleza</a></li>
                                                <li><a href="">Café</a></li>
                                                <li><a href="">Pájaros</a></li>
                                                <li><a href="">Ibagué</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog Detail Area -->
    </div>
</template>
<script>
    export default {
        name: 'blogdetail',
        data () {
            return {
                blog_post: [],
                last_blog_posts: []
            }
        },
        beforeCreate() {
            let blog_post_id = this.$route.query.post_id;
            this.$store.dispatch('findBlogPost', blog_post_id)
                .then(data => {
                    this.blog_post = data;
                })
                .catch(error => {
                    console.log(error);
                });
            this.$store.dispatch('listBlogPosts')
                .then(data => {
                    this.last_blog_posts = data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        mounted() {
            this.init();
            window.scrollTo(0, 0);
        },
        methods: {
            init() {
                this.hidePreloader();
                              
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
        }
    }
</script>