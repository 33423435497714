<template>
  <!-- <div>
    <b-carousel
      id="carousel-1"
    >
      <b-carousel-slide img-src="assets/images/slider-img/casita-banner.jpg"></b-carousel-slide>
      <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=55"></b-carousel-slide>

    </b-carousel>
  </div> -->
</template>

<script>
  export default {
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    }
  }
</script>