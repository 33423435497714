<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Contáctanos</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contáctanos</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start Contact Area -->
        <section class="contact-info">
            <div class="list-inline-wrapper p-top-80 p-bottom-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="d-flex align-items-center justify-content-center contact-info-box">
                                <div class="icon"><span><i class="mdi mdi-phone-in-talk"></i></span></div>
                                <div class="contents">
                                    <h6>+57 318 510 6016</h6>
                                    <span class="sub-text">Mar-Vier 1:00pm - 7:30pm</span><br>
                                    <span class="sub-text">Sab-Dom-Fest 10:00 am - 7:30pm</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="d-flex align-items-center justify-content-center contact-info-box">
                                <div class="icon"><span><i class="mdi mdi-telegram"></i></span></div>
                                <div class="contents">
                                    <h6>merengonbavaro@gmail.com</h6>
                                    <span class="sub-text">Escríbenos</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="d-flex align-items-center justify-content-center contact-info-box">
                                <div class="icon"><span><i class="mdi mdi-map-marker"></i></span></div>
                                <div class="contents">
                                    <h6>Ibagué, Colombia</h6>
                                    <span class="sub-text">km 5.5 cañón del Combeima</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact Area -->
        <!-- Start Get-In-Touch Area -->
        <section class="section getin-touch" id="formulario_contacto">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <h4>Escribe tu mensaje y con gusto te responderemos:</h4>
                        <div class="contact_form wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1000ms">
                            <form @submit.prevent="executeCaptcha">
                            <!--form-->
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="first_name" id="first_name" type="text" class="form-control" placeholder="Nombre*" v-model="contactData.name" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="last_name" id="last_name" type="text" class="form-control" placeholder="Apellidos*" v-model="contactData.last_name" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="email" id="email" type="email" class="form-control" placeholder="Correo electrónico*" v-model="contactData.email" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="phone" id="phone" type="text" class="form-control" placeholder="Teléfono" v-model="contactData.phone">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="city" id="city" type="city" class="form-control" placeholder="Ciudad" v-model="contactData.city">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <select name="message_reason" id="message_reason" class="form-control" placeholder="Motivo del mensaje*" v-model="contactData.message_type">
                                                <option disabled selected value="">Motivo del mensaje*</option>
                                                <option v-for="reason in reasons" :key="reason" :value="reason">
                                                    {{ reason }}
                                                </option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Tu mensaje...*" v-model="contactData.message" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group form-control">
                                            <input type="checkbox" id="terms" v-model="contactData.data_treatment" required>
                                            <label>
                                                &nbsp;Autorizo a Merengón Bávaro*
                                            </label>
                                        </div>
                                        <div class="gray-background">
                                            <p class="margin-p">
                                                para administrar los datos personales que he suministrado, bajo su <a href="/documentos/Política de tratamiento de datos Merengon Bavaro.pdf" target="_blank">política de tratamiento de datos personales.</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group form-control">
                                            <input type="checkbox" id="information" v-model="contactData.marketing_authorization">
                                            <label>
                                                &nbsp;Autorizo recibir información
                                            </label>
                                        </div>
                                        <div class="gray-background">
                                            <p class="margin-p">
                                                sobre productos, servicios y novedades del Merengón Bávaro ® en mi correo y/o teléfono.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div>
                                            <p>
                                                <small>
                                                    <b>Los campos marcados con * son obligatorios</b>
                                                </small>
                                            </p>
                                        </div>
                                        <!-- Componente recaptcha -->
                                        <vue-recaptcha
                                            ref="recaptcha"
                                            @verify="onCaptchaVerified"
                                            @expired="onCaptchaExpired"
                                            size="invisible"
                                            :sitekey="site_key">
                                        </vue-recaptcha>
                                        <div class="d-flex justify-content-start">
                                            <button type="submit" class="btn btn-sm theme-btn text-white text-uppercase send-btn">Enviar Mensaje</button>
                                            <!--button @click.prevent="executeCaptcha()" type="submit" class="btn btn-sm theme-btn text-white text-uppercase send-btn">Enviar Mensaje</button-->
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 col-sm-12">
                        <iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.9829997858824!2d-75.28290568626336!3d4.475914644814438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38c36e2f0a0f07%3A0x589637cef0b48525!2zTWVyZW5nw7NuIELDoXZhcm8!5e1!3m2!1ses!2sco!4v1675013273469!5m2!1ses!2sco" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> -->
                </div>
            </div>
        </section>
        <!-- End Get-In-Touch Area -->
        <!-- Sección te puede interesar -->
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h4>Te puede interesar</h4>
                            <li><router-link to="/preguntas">Preguntas frecuentes</router-link></li>
                            <li><router-link to="/postres">Productos</router-link></li>
                            <li><router-link to="/historia">Nosotros</router-link></li>
                        </div><br>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Sección te puede interesar -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    import {VueRecaptcha} from 'vue-recaptcha'

    export default {
        components: {
            VueRecaptcha
        },
        data() {
            return {
                    site_key: '6LfSo4IpAAAAANzgYJn4tX8CRkguxyAG7hEpfBMw', // Clave del sitio para recaptcha
                    recaptcha_success: false,
                    reasons: ['Felicitación', 'Petición', 'Queja', 'Reclamo', 'Sugerencia', 'Pregunta', 'Otro'],
                    contactData: {
                        "name": "",
                        "last_name": "",
                        "email": "",
                        "phone": "",
                        "city": "",
                        "message_type": "",
                        "message": "",
                        "data_treatment": false,
                        "marketing_authorization": false,
                        "state": 1
                    }
                }
        },
        name: 'contact',
        mounted() {
            this.init();
            //window.scrollTo(0, 0);
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            insertContact() {
                if (this.recaptcha_success) {
                    this.$store.dispatch('createContact', this.contactData)
                        .then(data => {
                            console.log("Información enviada");
                            // Envía e-mail de notificación
                            this.$store.dispatch('sendEmail', this.contactData)
                                .then(data => {
                                    console.log("Correo enviado");
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                            Swal.fire({
                                title: "Gracias por contactarnos",
                                text: "Información enviada",
                                icon: "success"
                                });
                            this.contactData = {
                                "name": "",
                                "last_name": "",
                                "email": "",
                                "phone": "",
                                "city": "",
                                "message_type": "",
                                "message": "",
                                "data_treatment": false,
                                "marketing_authorization": false,
                                "state": 1
                            }
                        })
                        .catch(error => {
                            console.log("Error al enviar información");
                            console.log(error);
                            Swal.fire({
                                title: "Error al enviar información",
                                text: "No olvides agregar los datos requeridos",
                                icon: "warning"
                            });
                        });
                } else {
                    console.log('recaptcha error')
                }
            },
            executeCaptcha: function () { //Inicio de funciones de recaptcha
                console.log('executeCaptcha')
                this.$refs.recaptcha.execute()
            },
            onCaptchaVerified: function (recaptchaToken) {
                this.verifyToken(recaptchaToken)
            },
            onCaptchaExpired: function () {
                this.recaptcha_success = false
                this.$refs.recaptcha.reset()
            },
            verifyToken (token) {
                this.$store.dispatch('verifyRecaptcha', token)
                    .then((data) => {
                        this.recaptcha_success = data
                        this.insertContact()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                } //Fin de funciones de recaptcha
        }
    }
</script>
<style>
    .margin-p {
        margin-left: 40px;
        margin-right: 20px;
    }
    .gray-background {
        background: #f4fafe;
    }
</style>