<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Start Login -->
        <div class="login-container">
            <div class="login-wrap">
                <div class="Home-links">
                    <a href="index.html"><i class="mdi mdi-home"></i></a>
                </div>
                <div class="form-title">
                    LOGIN
                </div>
                <form class="login_form">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input name="name" id="name" type="text" class="form-control" placeholder="User Name..." required="">
                            </div>
                            <div class="form-group">
                                <input name="name" id="name" type="password" class="form-control" placeholder="Password" required="">
                            </div>
                        </div>

                        <div class="col-sm-6 col-12">
                            <div class="custom-control login-links">
                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label text-black" for="customCheck1">Remember me</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <a href="#" class="text-black forgot-links"><strong>Forgot your password ?</strong></a>
                        </div>
                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn btn-hover theme-btn text-white text-uppercase login-btn">Login</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <p class="text-muted mt-3">New to Tomillo?<a href="signup.html" class="form-change-link">Sign Up</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- End Login -->
    </div>
</template>
<script>
    export default {
        name: 'login',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.hidePreloader();             
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            }
        }
    }
</script>