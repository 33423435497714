<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Nosotros</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start About Content Area -->
        <section class="section about-content-area no_padding">
            <div class="container">
                <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                    <div class="about-content">
                        <h4>
                            Nuestra historia
                        </h4>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <!-- <img src="assets/images/aboutus/about01.jpg" class="img-fluid" alt="" /> -->
                                    <img src="assets/images/historia/Una familia FOTO 1.jpg" class="img-fluid" alt="" />
                                </div>
                            </div>
                        </div><br>
                        <div class="text">Somos un emprendimiento familiar pionero en la preparación y distribución de merengón, postre acogido como tradición por los visitantes de una de las regiones más importantes de Ibagué: el cañón del Combeima.</div><br /><br>
                        <h5>
                            Una familia
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <!-- <img src="assets/images/aboutus/about01.jpg" class="img-fluid" alt="" /> -->
                                    <img src="assets/images/historia/Una_familia_FOTO 1.jpg" class="img-fluid" alt="" />
                                </div>
                            </div>
                        </div><br>
                        <div class="text">En 1991 éramos una familia en busca de crear una empresa propia, hasta que, un día, mientras explorábamos la riqueza en fauna, flora, café, panela y frutas frescas,  por las rutas y paisajes colombianos, probamos un postre que nos acompañaría a lo largo de treinta años: el merengón. </div><br /><br>
                        <h5>
                            De Cundinamarca, hasta el Tolima
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <!-- <img src="assets/images/aboutus/about01.jpg" class="img-fluid" alt="" /> -->
                                    <img src="assets/images/historia/casita_super_antigua.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                        </div><br>
                        <div class="text">Así, iniciamos un camino de comerciantes: en que el merengón era adquirido en las tierras frías de Cundinamarca y luego se vendía en Ibagué. Fueron numerosos viajes durante los cuales los merengones toleraban varias horas para llegar a una ciudad donde eran poco conocidos, por lo que muchas veces resultaban ser el postre diario de nuestra casa. </div><br />
                        <h5>
                            Todo cambió
                        </h5>
                        <div class="row align-items-center">
                            <!-- <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <img src="assets/images/aboutus/merengon.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                        </div><br>
                        <div class="text">Cierto día, la ruta habitual presenció un suceso que desencadenaría decisiones fundamentales en la empresa: un accidente de tránsito, del cual salimos ilesos, pero que nos motivó a suspender los viajes, asumir el rol de fabricantes, aprender la preparación del merengón e iniciar la producción en Ibagué, ciudad musical de Colombia.</div><br />
                        <h5>
                            Poco a poco
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <!-- <img src="assets/images/aboutus/about01.jpg" class="img-fluid" alt="" /> -->
                                    <img src="assets/images/historia/FOTO_4_Poco_a_poco.jpg" class="img-fluid" alt="" />
                                </div>
                            </div>
                        </div><br>
                        <div class="text">La forma de dar a conocer el merengón en el Tolima fue voz a voz, prueba a prueba, abordando a las personas para contarle acerca del producto. Finalmente, se adecuó una sencilla caseta a orilla de carretera, a unos metros de donde está actualmente. Con dedicación y constancia, poco a poco se dio a conocer el postre en la región.</div><br />
                        <h5>
                            ¿Por qué Bávaro?
                        </h5>
                        <div class="row align-items-center">
                            <!-- <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <img src="assets/images/aboutus/merengon.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                        </div><br>
                        <div class="text">Un interés especial en la cultura alemana, su gastronomía, vestuarios típicos y la característica cerveza de barril, además de admirar el paisaje montañoso de la vereda Berlín… nos llevó a imaginar la región Bávara, del otro lado del mundo, y a crear el ambiente ideal para otorgarle el nombre a la marca.</div><br />
                        <h5>
                            Nuevos sabores
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-12 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <img v-if="screenWidth >= 768" src="assets/images/historia/Banner_PC_Nuevos sabores FOTO 5_opción 1.png" alt="Large Image" class="img-fluid">
                                    <img v-else src="assets/images/historia/Banner_celular_Nuevos sabores FOTO 5_opción 1.png" alt="Small Image" class="img-fluid">
                                </div>
                            </div>
                        </div><br>
                        <div class="text">Aunque comenzamos con un solo sabor, en la medida en que nuestros visitantes sugieren nuevas frutas se ha desarrollado una carta que cuenta con catorce variedades, incluyendo café y aguacate. Cada una lleva fruta fresca cosechada en el cañón del combeima y municipios del Tolima.</div><br />
                        <br>
                        <h4>
                            Efemérides o hitos históricos
                        </h4><br>
                        <div style="border-left: 2px solid black; padding-left: 10px;">
                            <br><ul>
                                <li><h6>➣ 25 de noviembre de 1991:</h6>&emsp;Fundación del Merengón Bávaro.</li><br>
                                <li><h6>➣ 1991:</h6>&emsp;El primer sabor fue guanábana.</li><br>
                                <li><h6>➣ 1991-1995:</h6>
                                    <li>&emsp;• Se perfeccionó la fórmula.</li>
                                    <li>&emsp;• Se incorporaron nuevos sabores.</li>
                                    <li>&emsp;• Nueva ubicación del punto de venta.</li>
                                    <li>&emsp;• La marca Merengón Bávaro® se da a conocer.</li>
                                    <li>&emsp;• Mejoramiento de procesos, materia prima, insumos.</li>
                                    <li>&emsp;• Se convirtió en una fuente de ingresos familiar.</li>
                                </li><br>
                                <li><h6>➣ 1994:</h6>&emsp;Se adquiere la primera batidora y horno semi industrial.</li><br>
                                <li><h6>➣ 1998:</h6>&emsp;Formalización de la empresa.</li><br>
                                <li><h6>➣ 2000:</h6>&emsp;Adquisición del primer congelador industrial.</li><br>
                                <li><h6>➣ 2016:</h6>&emsp;Nacimiento de la leche asada y la merengada.</li><br>
                                <li><h6>➣ Abril 2017:</h6>&emsp;Nacimiento de la torta merengón.</li><br>
                                <li><h6>➣ Noviembre 2017:</h6>&emsp;Nacimiento de la corona de navidad.</li><br>
                                <li><h6>➣ 2021:</h6>&emsp;Nacimiento del merengón sin dulce.</li><br>
                                <li><h6>➣ 2022:</h6>&emsp; Nacimiento del sabajón.</li>
                            </ul><br>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Content Area -->
        <!-- Sección te puede interesar -->
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h4>Te puede interesar</h4>
                            <li><router-link to="/mision-vision">Misión, visión y principios</router-link></li>
                            <li><router-link to="/postres">Productos</router-link></li>
                        </div><br>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Sección te puede interesar -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    export default {
        data() {
            return {
                screenWidth: window.innerWidth
            }
        },
        name: 'about',
        mounted() {
            this.init();
            window.scrollTo(0, 0);
            window.addEventListener('resize', this.updateScreenSize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateScreenSize)
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
                this.initEnllax();
                this.windowScroll();
            },
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ENLLAX SCROLLING-----*/
            initEnllax() {
                $('.scroll-title').enllax();
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    self.initCounter();
                });
            },
            /*----COUNTER-----*/
            initCounter() {
                var length = $('#counter').length;
                if (length >= 1) {
                    var a = 0;
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counter-value').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                }
                            });
                        });
                        a = 1;
                    }
                }
            },
            updateScreenSize() {
                this.screenWidth = window.innerWidth
            }
        }
    }
</script>
<style scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}
.video-wrapper {
  width: 100%;
  padding-bottom: 56.25%; /* for 16:9 aspect ratio */
  position: relative;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no_padding {
    padding-top: 0;
}
</style>