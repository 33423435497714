<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Page Not Found</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">404</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start 404 Content Area -->
        <section class="section">
            <div class="error-page-area">
                <div class="container">
                    <div class="row">
                        <!--404 text image-->
                        <div class="col-xl-12">
                            <div class="text-center">
                                <div class="error-img">
                                    <img src="assets/images/404.jpg" class="img-fluid" alt="">
                                </div>
                                <h2>sorry we can't found anything</h2>
                                <div class="d-flex justify-content-center">
                                    <a href="index.html" class="btn btn-sm btn-hover theme-btn text-white text-uppercase send-btn">back to home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End 404 Content Area -->
    </div>
</template>
<script>

    export default {
        name: 'error',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.hidePreloader();             
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            }
        }
    }
</script>