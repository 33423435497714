<template>
    <!-- Start Navbar -->
    <nav class="navbar navbar-expand-lg fixed-top custom-nav sticky">
        <div class="container">
            <!-- LOGO -->
            <a class="navbar-brand brand-logo mr-4" href="/">
                <img src="assets/images/logo.png" class="img-fluid logo-light resize-logo" alt="">
                <img src="assets/images/dark-logo.png" class="img-fluid logo-dark resize-logo" alt="">
            </a>

            <div class="navbar-collapse collapse justify-content-center" id="navbarCollapse">
                <ul class="navbar-nav navbar-center" id="mySidenav">
                    <!-- <li class="nav-item">
                        <router-link class="nav-link" to="/">Inicio</router-link>
                    </li> -->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Productos
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/postres">Postres</router-link>
                            <router-link class="dropdown-item" to="/bebidas">Bebidas</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/preguntas">Preguntas frecuentes</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contacto">Contacto</router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link" to="/roulette">Ruleta del sabor</router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/momentos-bavaros">Momentos Bávaros</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Nosotros
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/mision-vision">Misión y visión</router-link>
                            <router-link class="dropdown-item" to="/historia">Nuestra historia</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/blog">Blog</router-link>
                    </li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Pages
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/error">404.</router-link>
                            <router-link class="dropdown-item" to="/blogdetail">Blog Detail.</router-link>
                            <router-link class="dropdown-item" to="/Login">Login.</router-link>
                            <router-link class="dropdown-item" to="/signup">Sign Up.</router-link>
                        </div>
                    </li> -->
                </ul>
                <div class="menu-close-btn"><i class="mdi mdi-close-circle-outline"></i></div>
            </div>
            <div class="header_btn">
                <router-link v-if="screenWidth >= 996" class="btn btn-hover btn-sm white-btn" to="/como-llegar">Cómo llegar<i class="mdi mdi-arrow-right ml-2"></i></router-link>
                <router-link v-else class="btn btn-hover btn-sm white-btn" to="/como-llegar">Cómo llegar<i class="mdi ml-2"></i></router-link>
               <button class="navbar-toggler ml-2 p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="mdi mdi-menu"></i>
                </button>
            </div>
        </div>
    </nav>
    <!-- End Navbar -->
</template>

<script>
    export default {
        name: "tomillo_header",
        data() {
            return {
                screenWidth: window.innerWidth
            }
        },
        mounted() {
            window.addEventListener('resize', this.updateScreenSize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateScreenSize)
        },
        methods: {
            updateScreenSize() {
                this.screenWidth = window.innerWidth
            }
        }
    }
</script>
<style scoped>
    .resize-logo {
        height: 70px !important;
    }
</style>