import { render, staticRenderFns } from "./postres.vue?vue&type=template&id=04651c64&scoped=true&"
import script from "./postres.vue?vue&type=script&lang=js&"
export * from "./postres.vue?vue&type=script&lang=js&"
import style0 from "./postres.vue?vue&type=style&index=0&id=04651c64&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04651c64",
  null
  
)

export default component.exports