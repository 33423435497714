<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Nosotros</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start About Content Area -->
        <section class="section about-content-area no_padding">
            <div class="container">
                <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                    <div class="about-content">
                        <h4>
                            Misión, visión y principios
                        </h4>
                        <div class="text">Nos motiva generar experiencias agradables al aire libre y productos artesanales de excelente calidad.</div><br /><br>
                        <h5>
                            MISIÓN
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <img v-if="screenWidth >= 768" src="assets/images/mision_vision/Misión_horizontal.jpg" alt="Large Image" class="img-fluid">
                                    <img v-else src="assets/images/mision_vision/Misión_vertical.jpg" alt="Small Image" class="img-fluid">
                                </div>
                            </div>
                        </div><br>
                        <div class="text">Dar a conocer el merengón en Ibagué y ser un punto de referencia de la vereda Berlín, ubicada en el cañón del Combeima. Nos motiva generar experiencias agradables al aire libre y productos fabricados artesanalmente, con materias primas seleccionadas, de excelente calidad.  Para hacerlo realidad, nuestro equipo de trabajo implementa cada día las mejores prácticas de manipulación de alimentos, atención al cliente, calidad e innovación en cada producto y proceso. </div><br /><br>
                        <h5>
                            VISIÓN
                        </h5>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                    <img v-if="screenWidth >= 768" src="assets/images/mision_vision/visión_horizontal.jpg" alt="Large Image" class="img-fluid">
                                    <img v-else src="assets/images/mision_vision/visión_vertical.jpg" alt="Small Image" class="img-fluid">
                                </div>
                            </div>
                        </div><br>
                        <div class="text">Seremos la empresa productora y distribuidora de merengón y postres a base de merengue más reconocida en Ibagué y el Tolima. Comercializaremos nuestros productos en otras ciudades del país y el mundo. Además,  seremos reconocidos en el mercado regional, nacional e internacional de postres, gracias a la fidelidad de nuestros clientes, quienes recorren el mundo y anhelan regresar al cañón del Combeima para deleitar sus paladares.</div><br />
                        <br>
                        <h5>
                            PRINCIPIOS Y VALORES
                        </h5><br>
                        <ul>
                            <li>✦ Disciplina</li>
                            <li>✦ Constancia</li>
                            <li>✦ Servicio</li>
                            <li>✦ Honestidad</li>
                            <li>✦ Resiliencia</li>
                            <li>✦ Fortaleza</li>
                            <li>✦ Cariño</li>
                            <li>✦ Dedicación</li>
                            <li>✦ Amor</li>
                            <li>✦ Generosidad</li>
                            <li>✦ Empatía</li>
                            <li>✦ Fraternidad</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Content Area -->
        <!-- Sección te puede interesar -->
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h4>Te puede interesar</h4>
                            <li><router-link to="/historia">Nuestra historia</router-link></li>
                            <li><router-link to="/postres">Productos</router-link></li>
                        </div><br>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Sección te puede interesar -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    export default {
        data() {
            return {
                screenWidth: window.innerWidth
            }
        },
        name: 'about',
        mounted() {
            this.init();
            window.addEventListener('resize', this.updateScreenSize);
            window.scrollTo(0, 0);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateScreenSize)
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
                this.initEnllax();
                this.windowScroll();
            },
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ENLLAX SCROLLING-----*/
            initEnllax() {
                $('.scroll-title').enllax();
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    self.initCounter();
                });
            },
            /*----COUNTER-----*/
            initCounter() {
                var length = $('#counter').length;
                if (length >= 1) {
                    var a = 0;
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counter-value').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                }
                            });
                        });
                        a = 1;
                    }
                }
            },
            updateScreenSize() {
                this.screenWidth = window.innerWidth
            }
        }
    }
</script>
<style scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}
.video-wrapper {
  width: 100%;
  padding-bottom: 56.25%; /* for 16:9 aspect ratio */
  position: relative;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no_padding {
    padding-top: 0;
}
</style>