import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
      // Define your state properties here
      products: [],
      contacts: [],
      dynamic_data: [],
      blog_posts: []
    },
    mutations: {
      // Define your mutations here
      setProducts(state, products) {
        state.products = products;
      },
      setContacts(state, contacts) {
        state.contacts = contacts;
      },
      addContact(state, contact) {
        state.contacts.push(contact);
      },
      setDynamicData(state, data) {
        state.dynamic_data = data;
      },
      setBlogPosts(state, posts) {
        state.blog_posts = posts;
      }
    },
    actions: {
      // Define your actions here
      testing() {
        console.log('testing');
      },
      listProducts({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('//merengonbavaro.com/api/products/')
            .then(response => {
              commit('setProducts', response.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      createContact({ commit }, contactData) {
        return new Promise((resolve, reject) => {
          axios.post('//merengonbavaro.com/api/contacts/create/', contactData)
            .then(response => {
              commit('addContact', response.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      retrieveDynamicData({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('//merengonbavaro.com/api/dynamic_data/')
            .then(response => {
              commit('setDynamicData', response.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      sendEmail({ commit }, emailData) {
        return new Promise((resolve, reject) => {
          axios.post('//merengonbavaro.com/api/contacts/send_email/', emailData)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      listBlogPosts({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('//merengonbavaro.com/api/blog/')
            .then(response => {
              commit('setBlogPosts', response.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      findBlogPost({ commit }, id) {
        return new Promise((resolve, reject) => {
          axios.get(`//merengonbavaro.com/api/blog/${id}/`)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      verifyRecaptcha({ commit }, token) { // Verificar el reCaptcha
        return new Promise((resolve, reject) => {
          axios.post('//merengonbavaro.com/api/security/recaptcha/', token)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    getters: {
      // Define your getters here
      getProducts: state => {
        return state.products;
      },
      getContacts: state => {
        return state.contacts;
      },
      getDynamicData: state => {
        return state.dynamic_data;
      },
      getBlogPosts: state => {
        return state.blog_posts;
      }
    }
});
