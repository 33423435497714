<template>
        <nav class="to-rigth">
        <ul>
            <!-- Add font awesome icons -->
            <li class="list"><a href="https://www.facebook.com/merengonbavaro91/" target="_blank" class="fa fa2 fa-facebook"></a></li>
            <li class="list"><a href="https://www.instagram.com/merengonbavaro" target="_blank" class="fa fa-instagram"></a></li>
        </ul>
    </nav>
</template>
<script>
    export default {
        data () {
            return {
            }
        }
    }
</script>
<style scoped>
        .fa {
            padding: 20px;
            font-size: 30px;
            text-align: center;
            text-decoration: none;
            margin: 5px 2px;
            border-radius: 50%;
        }
        .fa2 {
            padding: 25px;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 30px;
            text-align: center;
            text-decoration: none;
            margin: 5px 2px;
            border-radius: 50%;
        }

        .fa:hover {
            opacity: 0.7;
        }

        .fa-facebook {
            background: #3B5998;
            color: white;
        }

        .fa-instagram {
            background: #E1306C;
            color: white;
        }

        .to-rigth {
            position: fixed !important;
            bottom: 100px !important;
            right: 28px !important;
            z-index:1000 !important;
            zoom: 0.67;
        }

        .list {
            list-style-type: none;
        }
</style>