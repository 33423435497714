import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import about from '@/components/about'
import faq from '@/components/faq'
import postres from '@/components/postres'
import bebidas from '@/components/bebidas'
import blog from '@/components/blog'
import blogdetail from '@/components/blogdetail'
import comoLlegar from '@/components/comoLlegar'
import contacto from '@/components/contacto'
import error from '@/components/error'
import login from '@/components/login'
import signup from '@/components/signup'
import momentoBavaros from '@/components/momentoBavaros'
import misionVision from '@/components/misionVision'
import historia from '@/components/historia'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index
        },
        {
            path: '/about',
            name: 'about',
            component: about
        },
        {
            path: '/preguntas',
            name: 'faq',
            component: faq
        },
        {
            path: '/postres',
            name: 'postres',
            component: postres
        },
        {
            path: '/bebidas',
            name: 'bebidas',
            component: bebidas
        },
        {
            path: '/blog',
            name: 'blog',
            component: blog
        },
        {
            path: '/blogdetail',
            name: 'blogdetail',
            component: blogdetail
        },
        {
            path: '/error',
            name: 'error',
            component: error
        },
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/signup',
            name: 'signup',
            component: signup
        },
        {
            path: '/como-llegar',
            name: 'comoLlegar',
            component: comoLlegar
        },
        {
            path: '/contacto',
            name: 'contacto',
            component: contacto
        },
        {
            path: '/momentos-bavaros',
            name: 'momentosBavaros',
            component: momentoBavaros
        },
        {
            path: '/mision-vision',
            name: 'misionVision',
            component: misionVision
        },
        {
            path: '/historia',
            name: 'historia',
            component: historia
        }
    ]
})
