var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('section',{staticClass:"section como-llegar"},[_vm._m(2),_c('div',{staticClass:"container"},[_vm._m(3),_c('div',{staticClass:"row align-items-center texto-como-llegar"},[_vm._m(4),_c('div',{staticClass:"col-md-6 col-sm-12"},[(_vm.screenWidth >= 768)?_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/images/como_llegar/jeep.png","alt":""}}):_c('img',{staticClass:"img-fluid",staticStyle:{"margin-top":"10px"},attrs:{"src":"assets/images/como_llegar/jeep.png","alt":""}}),_c('br')])]),_c('div',{staticClass:"row align-items-center texto-como-llegar"},[_vm._m(5),_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('br'),_c('h3',[_vm._v("Vehículo particular")]),_c('br'),(_vm.screenWidth >= 768)?_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/images/como_llegar/particular.png","alt":"Large Image"}}):_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/images/como_llegar/particular_cel.png","alt":"Small Image"}}),_c('br'),_c('br'),_c('br'),_c('h5',[_vm._v("Por la carrera primera de Ibagué:")]),_c('br'),_vm._m(6),_vm._m(7),_c('br'),_c('h5',[_vm._v("Por la carrera tercera de Ibagué:")]),_c('br'),_vm._m(8),_vm._m(9)])])])]),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"section-title"},[_c('h4',[_vm._v("Te puede interesar")]),_c('li',[_c('router-link',{attrs:{"to":"/postres"}},[_vm._v("Productos")])],1),_c('li',[_c('router-link',{attrs:{"to":"/preguntas"}},[_vm._v("Preguntas frecuentes")])],1),_c('li',[_c('router-link',{attrs:{"to":"/historia"}},[_vm._v("Nosotros")])],1)]),_c('br')])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"inner-hero-banner"},[_c('div',{staticClass:"inner-hero-text"},[_c('br'),_c('br'),_c('h1',[_vm._v("¿Cómo llegar?")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Inicio")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("¿Cómo llegar?")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card blog--card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"texto-como-llegar"},[_vm._v("¿Conoces el nombre de la vereda en la que se encuentra nuestra casa Merengón Bávaro? Descubre la encantadora Berlín, una de las veredas que es tímidamente mencionada y que tiene los brazos abiertos para recibir a nuevos visitantes.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('div',{staticClass:"contact_form wow fadeInUp container-iframe",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1000ms"}},[_c('iframe',{staticClass:"responsive-iframe",attrs:{"src":"https://www.youtube.com/embed/ypMFBTFpQN8?rel=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])]),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('div',{staticClass:"container-iframe"},[_c('iframe',{staticClass:"responsive-iframe",staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.9829997858824!2d-75.28290568626336!3d4.475914644814438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38c36e2f0a0f07%3A0x589637cef0b48525!2zTWVyZW5nw7NuIELDoXZhcm8!5e1!3m2!1ses!2sco!4v1675013273469!5m2!1ses!2sco","width":"600","height":"450","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center texto-como-llegar"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('br'),_c('h3',[_vm._v("Transporte público")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-12"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/images/como_llegar/ruta_48.png","alt":""}}),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 col-sm-12"},[_c('p',[_vm._v(" En la avenida quinta de Ibagué puedes tomar la ruta de bus 48, por solo $2.700 pesos colombianos, en puntos como:")]),_c('ol',[_c('li',[_vm._v("Supermercado Surtiplaza Salado")]),_c('li',[_vm._v("Almacén Éxito de la calle 80")]),_c('li',[_vm._v("Centro comercial Multicentro")]),_c('li',[_vm._v("Centro médico Javeriano")]),_c('li',[_vm._v("Iglesia El Carmen")]),_c('li',[_vm._v("Calle 15")]),_c('li',[_vm._v("Parque de la Música")]),_c('li',[_vm._v("Barrio Libertador")])]),_c('p',[_vm._v(" En la carrera primera con calle 13 de Ibagué puedes tomar un carro típico o campero y sentir la aventura de recorrer parte del cañón del Combeima. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Duración:")]),_vm._v(" 12 min aprox "),_c('br'),_c('b',[_vm._v("Distancia:")]),_vm._v(" 5 km aprox")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Toma la carrera primera, pasando por la clínica Tolima y el parque de la música.")]),_c('li',[_vm._v("Ingresa al barrio Libertador.")]),_c('li',[_vm._v("Sigue la única vía hacia el cañón del Combeima, que te llevará a nuestro punto de venta.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Duración:")]),_vm._v(" 12 min aprox "),_c('br'),_c('b',[_vm._v("Distancia:")]),_vm._v(" 5 km aprox")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Toma la carrera tercera, pasando por la plaza de Bolívar.")]),_c('li',[_vm._v("Entra al barrio La Pola, para tomar la calle quinta.")]),_c('li',[_vm._v("Gira a la izquierda, hacia el barrio Libertador.")]),_c('li',[_vm._v("Sigue la única vía hacia el cañón del Combeima, que te llevará a nuestro punto de venta.")])])
}]

export { render, staticRenderFns }