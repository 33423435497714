<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">About Us</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start About Content Area -->
        <section class="section about-content-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="image-container mt-0 wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="video-wrapper">
                                <a class="popup-youtube" data-toggle="modal" data-target="#videomodal" href="javascript:void(0)">
                                    <img src="assets/images/video-frame.png" class="img-fluid" alt="">
                                    <span class="video-play-button">
                                        <span></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <h4>
                                    What We Do
                                </h4>
                                <div class="text">is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12 order-md-2">
                        <div class="about-image-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <img src="assets/images/aboutus/team.jpg" class="img-fluid" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 order-md-1">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <h4>
                                    Most Dedicated team we have
                                </h4>
                                <div class="text">this is simply dummy text of the printing and typesetting industry. this has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="videomodal" tabindex="-1" role="dialog" aria-labelledby="videomodal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content transparent-modal">
                            <div class="modal-body p-0">
                                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <iframe src="https://www.youtube.com/embed/UB1O30fR-EE?controls=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Content Area -->
        <!-- Start About Countdown Area-->
        <section class="parallax parallax-box section">
            <div id="counter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-rocket"></i></div>
                                <h3 id="number1" class="number counter-value" data-count="12">0</h3>
                                <span></span>
                                <p>Projects done</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-account-group"></i></div>
                                <h3 id="number2" class="number counter-value" data-count="55">0</h3>
                                <span></span>
                                <p>Happy clients</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-coffee"></i></div>
                                <h3 id="number3" class="number counter-value" data-count="360">0</h3>
                                <span></span>
                                <p>Cups of coffee</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-trophy"></i></div>
                                <h3 id="number4" class="number counter-value" data-count="10">0</h3>
                                <span></span>
                                <p>Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Countdown Area-->
        <!-- Start About Team Area-->
        <section class="section team-area">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>The Best Team</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal" style="transform: translateX(80px);">The Best Team</span>
                        <p class="section_subtitle mx-auto">dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/1.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>john Walker</h4>
                                <p>CO Founder</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/2.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>Saarah Cena</h4>
                                <p>Creative Designer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/3.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>Paul Rock</h4>
                                <p>Web Designer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/4.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>Daniel Smith</h4>
                                <p>SEO Expert</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/5.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>Adam John</h4>
                                <p>Web Developer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow fadeInUp" data-wow-duration="1.5s">
                            <div class="team-photo">
                                <img src="assets/images/aboutus/team/6.jpg" class="img-fluid" alt="">
                                <div class="team-content">
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="mdi mdi-facebook-box"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#"><i class="mdi mdi-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="person-name">
                                <h4>kathy Snow</h4>
                                <p>BDE Expert</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Team Area-->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    export default {
        name: 'about',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
                this.initEnllax();
                this.windowScroll();
            },
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ENLLAX SCROLLING-----*/
            initEnllax() {
                $('.scroll-title').enllax();
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    self.initCounter();
                });
            },
            /*----COUNTER-----*/
            initCounter() {
                var length = $('#counter').length;
                if (length >= 1) {
                    var a = 0;
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counter-value').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                }
                            });
                        });
                        a = 1;
                    }
                }
            }
        }
    }
</script>