<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Postres</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Postres</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start Portfolio Area -->
        <section class="section portfolio-section">
            <div class="container">
                <div class="d-flex justify-content-center">
                    <!--   Portfolio Filters   -->
                    <ul id="portfolio-filter" class="portfolio-filter filters">
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button active" @click="changeMyFilter('all')">Todos</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Merengon')">Merengón</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Suspiros')">Suspiros</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Merengada')">Merengada</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Frutas con crema')">Frutas con crema</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Otros postres')">Otros postres</a>
                        </li>
                        <li class="button-border list-inline-item">
                            <a href="#" class="pill-button" @click="changeMyFilter('Para compartir')">Para compartir</a>
                        </li>
                    </ul>
                </div>
                <div class="portfolio-items row force-height">
                 <!-- iterations of products list -->
                    <div v-for="(product) in productsList" class="col-lg-4 col-sm-6 portfolio-item fit-size" v-if="product.product_type == 'Postre' && product.state === 1 && (my_filter === product.product_subtype || my_filter === 'all')">
                        <div class="image-border">
                            <div class="portfolio-box">
                                <img :id="product.id" :src="`assets/images/portfolio/postres/${product.picture_path}`" class="img-fluid js-zoom-gallery picure-border-style" alt="" :href="`assets/images/portfolio/postres/big/${product.picture_path}`" :pr_name="product.name" :pr_cost="product.cost" :pr_desc="product.description">
                                <div class="portfolio-icon d-flex align-items-center justify-content-center">
                                    <i class="mdi mdi-magnify-plus-outline"></i>
                                </div>
                                <div class="img-overlay text-center">
                                    <h5 class="mb-0">{{ product.name }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end iterations -->
            </div>
        </section>
        <!-- End Portfolio Area -->
    </div>
</template>
<script>

    // import store from '../store';

    //ISOTOP JS
    import '../assets/js/jquery.isotope.min.js';
    //MAGNIFIC POPUP JS
    import 'magnific-popup/dist/jquery.magnific-popup.min.js';
    import 'magnific-popup/dist/magnific-popup.css';
    export default {
        name: 'portfolio',
        data () {
            return {
                productsList: [],
                my_filter: 'all'
            }
        },
        beforeCreate() {
            this.$store.dispatch('listProducts')
                .then(data => {
                    this.productsList = data;
                    this.productsList.sort((a, b) => a.order - b.order);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        mounted() {
            this.init();
            window.scrollTo(0, 0);
            this.testingVuex();
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initIsotop();
                this.initMagnificPopup();
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----ISOTOP JS-----*/
            initIsotop() {
                var $container = $('.portfolio-items');
                $container.imagesLoaded(function () {
                    $container.isotope({
                        filter: '*',
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                });

                $('.portfolio-filter a').click(function () {
                    $('.portfolio-filter .current').removeClass('current');
                    $(this).addClass('current');
                    var selector = $(this).attr('data-filter');
                    $container.isotope({
                        filter: selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                    return false;
                });
            },
            /*----MAGNIFIC POPUP JS-----*/
            initMagnificPopup() {
            if (('.portfolio-items').length > 0) {
                $('.portfolio-items').each(function () {
                $(this).magnificPopup({
                    delegate: '.js-zoom-gallery',
                    type: 'image',
                    image: {
                    titleSrc: function(item) {
                        let pr_cost = Number(item.el.attr('pr_cost')).toLocaleString();
                        let pr_all_text = "<h5>" + item.el.attr('pr_name') + "</h5><br>$" + pr_cost + "<br><br>" + item.el.attr('pr_desc');
                        return '<label style="position: relative;bottom: 150px;left: 5px; !important; display: inline-block !important;padding: 10px !important;background-color: rgba(236, 103, 25, 0.8) !important;border-radius: 5px !important;box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;">'+pr_all_text+'</label>';
                    }
                    },
                    gallery: {
                    enabled: true
                    }
                });
                });
            }
            },
            testingVuex() {
                this.$store.dispatch('testing');
            },
            changeMyFilter(filter) {
                this.my_filter = filter;
            }
        },
        watch: {
            my_filter (newVal, oldVal) {
                console.log('Filtro', newVal)
                console.log(('.portfolio-item').length)
                $('.portfolio-item').each(function () {
                    $(this).css({opacity: 0.0, visibility: "visible"}).animate({opacity: 1.0, visibility: "visible"}, 400);
                });
            }
        }
    }
</script>
<style scoped>
    .force-height {
        height: 100% !important;
    }
    .fit-size {
        width: 100% !important;
        height: 100% !important;
    }
    .picure-border-style {
        border-radius: 30px !important;
    }
</style>